import React from 'react'
import { useStaticQuery, graphql, Link as GLink } from 'gatsby'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'
import { Box, Text, Card, Flex, Link, Button } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Section from '@components/Section'
import Seo from '@widgets/Seo'

/**
 * Shadow me to add your own content
 */

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, null, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, null, `1/2`]
  }
}

const ExternalLink = ({ children, href, ...other }) => {
  return (
    <Link href={href} target="_blank" rel="noopener noreferrer" {...other}>
      {children}
    </Link>
  )
}

export default props => {
  const data = useStaticQuery(aboutQuery)
  const image = getImage(data.avatar)

  return (
    <Layout {...props}>
      <Seo title='About' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header='About Me'
            subheader='데이터 분석과 시각화에 관심이 많은 프로덕트 오너입니다.'
          />
          <Divider />
          {/* <Box sx={styles.imageWrapper}>
            <Img image={image} />
            <Button sx={styles.button}>Contact Me</Button>
          </Box>
          <Divider /> */}
          <Flex sx={styles.grid}>
            <Box sx={styles.column}>
              <Section title='My Story'>
                <Text variant='p'>
                  데이터 분석가로 커리어를 시작했지만, 현재는 더 많은 사람들이 데이터를 업무에 활용할 수 있도록 돕는 제품을 만들기 위해 프로덕트 오너로 일하고 있습니다.
                </Text>
                <Text variant='p'>
                  실험, 시각화 그리고 인과 모형에 많은 관심을 가지고 있습니다.
                </Text>

                <Text variant='h5'>
                  이력
                </Text>
                
                <Text variant='p'>
                  <ul>
                    <li><b>넘버웍스</b> Data Scientist (2016.01 ~ 2016.05)</li>
                    <li><b>카카오</b> Data Scientist (2016.05 ~ 2019.09)</li>
                    <li><b>데이터라이즈</b> Product Owner (2019.10 ~ )</li>
                  </ul>
                </Text>

                <Text variant='h5'>
                  강의/외부 발표
                </Text>

                <Text variant='p'>
                  <ul>
                    <li><b>꿈꾸는 데이터 디자이너</b> 교육과정 R 강의 (1기, 2기)</li>
                    <li><b>패스트캠퍼스</b> R을 활용한 데이터 분석 입문, R을 활용한 실전 데이터 분석 프로젝트 강의 (2019)</li>
                    <li><b>2021 한국 R 컨퍼런스 발표</b> 프로덕션 수준의 리포트 자동화 시스템 만들기</li>
                    <li><b>2021 네이버 DEVIEW 발표</b> 인과효과 추정 모형으로 온사이트 마케팅 캠페인 효과 분석하기</li>
                  </ul>
                </Text>
              </Section>
            </Box>
            <Box sx={styles.column}>
              <Section title='Skills'>
                <Card variant='paper'>
                    <Text variant='p'>
                        <ul>
                            <li>R과 Python을 활용한 데이터 전처리/분석/시각화</li>
                            <li>React/Vue + Node를 활용해 빠른 MVP 작업 가능</li>
                            <li>다양한 분야에 관심을 가지고, 빠르게 습득하는 학습 능력</li>
                        </ul>
                    </Text>
                </Card>
              </Section>
              <Divider />
              <Section title='Community'>
                <Card variant='paper'>
                  <Link as={ExternalLink} href={"https://playinpap.github.io"}>프로덕트 데이터 분석 커뮤니티 <b>PAP</b></Link>의 운영진으로 활동하고 있습니다.
                </Card>
              </Section>
              {/* <Divider />
              <Section title='Creative Designing'>
                <Card variant='paper'>
                  A core responsibility of the designer's job is to present
                  information in a way that is both accessible and memorable.
                </Card>
              </Section> */}
            </Box>
          </Flex>
        </Main>
      </Stack>
    </Layout>
  )
}

const aboutQuery = graphql`
  query AboutQueryShadowed {
    avatar: file(absolutePath: { regex: "/about.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1140
          height: 500
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
  }
`
